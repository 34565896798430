<template>
  <div class="partner-custom-form flights-form">
    <flights-form
      v-if="flight"
      :flightObject="flight"
    />
  </div>
</template>

<script>
import FlightsForm from "./components/FlightForm.vue";

export default {
  components: {
    FlightsForm,
  },
  data() {
    return {
      flight: null,
    };
  },
  created() {
    this.loadFlight();
  },
  methods: {
    loadFlight() {
      const id = this.$route.params.id;
      this.$Flights.getResource({ id }).then((response) => {
        this.flight = response.data;
        this.flight.date = new Date(response.data.date)
        this.flight.status = {
          label: this.$helper.getEnumTranslation(
            "flight_status",
            this.flight.status,
            this.$i18n.locale
          ),
          id: this.flight.status,
        };
      });
    },
  },
};
</script>
