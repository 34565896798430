<template>
  <div class="content-box">
    <b-form @submit.prevent="onSubmit">
      <div v-if="flight.id" :class="{ row }" class="form-group">
        <label for=""
          ><strong>{{ $t("flight.code") }}</strong></label
        >
        <div class="row">
          <div class="col-12">
            <b-form-input v-model="flight.code" disabled />
          </div>
        </div>
      </div>

      <div
        :class="{ row, error: v$.flight.fromPlace.$errors.length }"
        class="form-group"
      >
        <label for="">{{ $t("flight.from") }}</label>
        <div class="row">
          <div class="col-12">
            <b-form-input v-model="flight.fromPlace" />
            <div
              class="input-errors"
              v-for="error of v$.flight.fromPlace.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="{ row, error: v$.flight.toPlace.$errors.length }"
        class="form-group"
      >
        <label for="">{{ $t("flight.to") }}</label>
        <div class="row">
          <div class="col-12">
            <b-form-input v-model="flight.toPlace" />
            <div
              class="input-errors"
              v-for="error of v$.flight.toPlace.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="userData && userData.role === 'ROLE_ADMIN'"
        :class="{ row }"
        class="form-group"
      >
        <label for="">
          {{ $t("flight.alternativeName") }}
          <img
            class="label-tooltip"
            src="@/assets/images/icons/tooltip.svg"
            v-b-tooltip.hover
            :title="$t('flight.alternativeNameTooltip')"
            alt=""
          />
        </label>
        <div class="row">
          <div class="col-12">
            <b-form-input v-model="flight.name" />
          </div>
        </div>
      </div>

      <div
        :class="{ row, error: v$.flight.date.$errors.length }"
        class="form-group"
      >
        <label>{{ $t("flight.dateTime") }}</label>
        <div class="row">
          <div class="col-12">
            <datepicker
              class="form-input"
              :minDate="new Date()"
              v-model="flight.date"
              format="dd. MM. yyyy | HH:mm"
              previewFormat="dd. MM. yyyy | HH:mm"
            ></datepicker>
            <div
              class="input-errors"
              v-for="error of v$.flight.date.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="{ row, error: v$.flight.freePlaces.$errors.length }"
        class="form-group"
      >
        <label for="">{{ $t("flight.freePlaces") }}</label>
        <div class="row">
          <div class="col-12">
            <b-form-input
              type="number"
              min="0"
              step="1"
              v-model="flight.freePlaces"
            />
            <div
              class="input-errors"
              v-for="error of v$.flight.freePlaces.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for=""
          >{{ $t("flight.aircraftType") }}
          <span>({{ $t("forms.nonRequired") }})</span>
        </label>
        <div class="row">
          <div class="col-12">
            <b-form-input v-model="flight.aircraftType" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for=""
          >{{ $t("flight.weightLimit") }} ({{ $t("forms.nonRequired") }})
          <img
            class="label-tooltip"
            src="@/assets/images/icons/tooltip.svg"
            v-b-tooltip.hover
            :title="$t('flight.weightLimitTooltip')"
            alt=""
          />
        </label>
        <div class="row">
          <div class="col-12">
            <b-form-input
              type="number"
              min="0"
              step="1"
              v-model="flight.weightLimit"
            />
          </div>
        </div>
      </div>

      <div
        :class="{ row, error: v$.flight.purchasePrice.$errors.length }"
        class="form-group"
      >
        <label>
          {{ $t("flight.price") }}
          <img
            class="label-tooltip"
            src="@/assets/images/icons/tooltip.svg"
            v-b-tooltip.hover
            :title="$t('flight.priceTooltip')"
            alt=""
          />
        </label>
        <div class="row flight-price-type-row">
          <div class="col-6 price">
            <b-form-input v-model="flight.purchasePrice" />
            <div
              class="input-errors"
              v-for="error of v$.flight.purchasePrice.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </div>

          <div class="col-1 separator">/</div>

          <div class="col-5 type">
            <v-select
              v-model="flight.perPerson"
              :clearable="false"
              :options="priceTypes"
            />
          </div>

          <div
            v-if="flight.purchasePrice && flight.purchasePrice >= 0"
            class="col-12 final-price"
          >
            <span
              >{{ $t("flight.sellPrice") }}:
              <strong>{{ $helper.priceFormat(sellPrice, 2) }}</strong></span
            >
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="">{{ $t("flight.description") }} ({{ $t("forms.description") }})</label>
        <div class="row">
          <div class="col-12">
            <b-form-textarea v-model="flight.description" id="h-notes"/>
          </div>
        </div>
      </div>

      <div
        v-if="statuses && statuses.length"
        :class="{ row, error: v$.flight.status.$errors.length }"
        class="form-group"
      >
        <label>{{ $t("flight.status") }}</label>
        <div class="row">
          <div class="col-12">
            <v-select
              :clearable="false"
              :searchable="false"
              v-model="flight.status"
              :options="statuses"
            />
            <div
              class="input-errors"
              v-for="error of v$.flight.status.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="userData && userData.role === 'ROLE_ADMIN' && !flight.id"
        :class="{ row, error: v$.flight.partner.$errors.length }"
        class="form-group"
      >
        <label>{{ $t("flight.partner") }}</label>
        <div class="row">
          <div class="col-12">
            <v-select v-model="flight.partner" :options="users" />
            <div
              class="input-errors"
              v-for="error of v$.flight.partner.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </div>
        </div>
      </div>

      <gallery
        :files="files"
        :galleryTitle="$t('flight.images')"
        v-if="flight['@id']"
        :resource="flight['@id']"
        file-attribute="FILES"
        @updated="updateFiles"
      />

      <div class="mb-5 mt-4" :class="{ row, error: v$.consent.$errors.length }">
        <b-form-checkbox v-model="consent">{{
          $t("flight.consent")
        }}</b-form-checkbox>
        <div
          class="input-errors"
          v-for="error of v$.consent.$errors"
          :key="error.$uid"
        >
          <small class="text-danger">{{ $t("flight.consentAgree") }}</small>
        </div>
      </div>

      <b-button v-if="enabledEdit" type="submit" variant="primary">{{
        !flight.id ? $t("flight.create") : $t("flight.update")
      }}</b-button>
    </b-form>
  </div>
</template>

<script>
import moment from "moment";
import vSelect from "vue-select";
import useVuelidate from "@vuelidate/core";
import { sameAs } from "@vuelidate/validators";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Gallery from "./Gallery.vue";

export default {
  components: {
    Datepicker,
    vSelect,
    Gallery
  },
  props: {
    flightObject: { Object, required: true },
    action: { type: String, default: null },
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      flight: {
        fromPlace: { required: this.$translateError("required") },
        toPlace: { required: this.$translateError("required") },
        freePlaces: { required: this.$translateError("required") },
        date: { required: this.$translateError("required") },
        purchasePrice: {
          required: this.$translateError("required"),
          decimal: this.$translateError("decimal"),
        },
        status: { required: this.$translateError("required") },
        partner: { required: this.$translateError("required") },
      },
      consent: {
        required: this.$translateError("required"),
        sameAs: sameAs(true),
      },
    };
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      flight: this.flightObject,
      hm: "",
      statuses: [],
      users: [],
      files: [],
      consent: false,
      priceTypes: [
        {
          label: this.$t("flight.perPerson"),
          id: true,
        },
        {
          label: this.$t("flight.perPlane"),
          id: false,
        },
      ],
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  computed: {
    enabledEdit() {
      if (this.flight.id && this.flight.status === "CLOSED") {
        return false;
      }
      return true;
    },
    sellPrice() {
      let provision = 0;
      const price = parseFloat(this.flight.purchasePrice);
      if (price <= 150) {
        provision = 0.24;
      } else if (price > 150 && price <= 240) {
        provision = 0.2;
      } else if (price > 240 && price <= 440) {
        provision = 0.16;
      } else if (price > 440 && price <= 640) {
        provision = 0.12;
      } else if (price > 640 && price <= 1040) {
        provision = 0.09;
      } else if (price > 1040 && price <= 1540) {
        provision = 0.075;
      } else if (price > 1540 && price <= 2640) {
        provision = 0.06;
      } else if (price > 2640 && price <= 3740) {
        provision = 0.05;
      } else if (price > 3740 && price <= 15140) {
        provision = 0.04;
      } else if (price > 15141 && price <= 51140) {
        provision = 0.035;
      } else if (price > 51140) {
        provision = 0.03;
      }
      return Math.ceil((price + price * provision) / 10) * 10;
    },
  },
  async created() {
    await this.getEnumList("flight_status", this.statuses);
    if (this.userData && this.userData.role === "ROLE_PARTNER") {
      this.statuses = []
      this.flight.partner = { id: this.userData["@id"] }
      if (!this.flight.id) {
        this.flight.status = { id: "NOT_PUBLISHED" }
      }
    }
    if (
      this.userData &&
      this.userData.role === "ROLE_ADMIN" &&
      !this.flight.id
    ) {
      await this.getUserList({ params: { role: "ROLE_PARTNER" } }, this.users);
      if (this.flight.partner) {
        const existingUser = this.users.find(
          (u) => u.id === this.flight.partner
        );
        this.flight.partner = existingUser;
      }
    }
    if (this.flight.id) {
      this.flight.perPerson = this.priceTypes.find(
        (u) => u.id === this.flight.perPerson
      );
    } else {
      this.flight.perPerson = {
        label: this.$t("flight.perPlane"),
        id: false,
      };
    }
    if (!this.flight.description) {
      this.flight.description = this.$t("flight.descriptionPlaceholder")
    }
    if (this.flight.id) {
      this.$Flights
        .getResourceFiles({id: this.flight.id})
        .then((response) => {
          if (response.status === 200) {
            this.files = response.data["hydra:member"].map(f => {
              f.index = 0
              return f
            });
            if(this.flight.filesOrder){
              this.flight.filesOrder.forEach((fo, ix) => {
                const file = this.files.find(f => f['@id'] === fo)
                if (file) {
                  file.index = ix
                }
              })
              this.files.sort((a, b) => parseFloat(a.index) - parseFloat(b.index));
            }
          }
        });
    }
  },
  methods: {
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          fromPlace: this.flight.fromPlace,
          toPlace: this.flight.toPlace,
          name: this.flight.name,
          freePlaces: parseInt(this.flight.freePlaces),
          date: moment(this.flight.date).format(),
          purchasePrice: this.flight.purchasePrice.toString(),
          price: this.sellPrice.toString(),
          status: this.flight.status.id,
          partner: this.flight.partner.id,
          perPerson: this.flight.perPerson.id,
          description: this.flight.description,
        };

        if (this.flight.aircraftType) {
          body.aircraftType = this.flight.aircraftType;
        }

        if (this.flight.weightLimit) {
          body.weightLimit = parseInt(this.flight.weightLimit);
        }

        this.flight.id
          ? this.update(
              this.$Flights,
              this.flight.id,
              body,
              this.$t("flight.updated"),
              null,
              this.success,
              this.error
            )
          : this.create(
              this.$Flights,
              body,
              this.$t("flight.created"),
              null,
              this.success,
              this.error
            );
      }
    },
    ilustrationImageChange(data) {
      this.flight.file = data["@id"];
      this.flight.fileURL = data.url;
    },
    updateFiles(data) {
      const filesOrder = data.map(file => file['@id'])
      this.update(this.$Flights, this.flight.id, { filesOrder }, this.$t("flight.updated"));
    },
    success() {
      this.$router.push(`/flights`);
    },
    error() {},
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
